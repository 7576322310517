import React from 'react'
import { StaticQuery, graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import Button from '../components/button'

import SEO from '../components/seo'
import OfferPostItem from '../components/offer-post-item'

import Breadcrumbs from '../components/breadcrumbs'

const BlogPostsLayoutContainer = styled.div`
  display: flex;
  padding: 2em 1em;
  flex-wrap: wrap;
`

const Heading = styled.div`
  background: #fff;

  padding: 3em 1em;
  padding-bottom: 5em;
  text-align: center;
  margin-bottom: -5em;
  h1 {
    font-size: calc(1.15vw + 1.25em);
    margin-bottom: 0.15em;
  }
  p {
    color: #666;
    margin-bottom: 0.15em;
  }
`

const OffersPage = props => {
  const data = useStaticQuery(graphql`
    query GetAllOffers {
      allContentfulOffer {
        edges {
          node {
            id
            title
            slug
            excerpt {
              excerpt
            }
            expiresOn(formatString: "MM/DD/YYYY")
            image {
              gatsbyImageData(width: 400, quality: 70, placeholder: NONE)
            }
            notificationBarActionText
            offerCode
          }
        }
      }
    }
  `)

  return (
    <>
      <Layout pageProps={props}>
        <div className="bg-white border-b border-gray-100">
          <nav
            className="flex container mx-auto px-4 py-3"
            aria-label="Breadcrumb"
          >
            <Breadcrumbs
              links={[
                { to: '/', label: 'Home' },
                {
                  to: props.location.pathname,
                  label: `Offers`,
                },
              ]}
            />
          </nav>
        </div>
        <SEO
          title={'Solid Construction & Design Offers & Discounts'}
          description={
            "Take full advantage of our exclusive deals and promotions to ensure incredible savings for your upcoming renovation."
          }
        />
        <Heading style={{ paddingTop: '1em' }}>
          <h1>Solid Construction & Design Offers</h1>
          <p>
          Ensure you don't miss out on the chance to save big on your upcoming renovation. 
          </p>
        </Heading>
        <BlogPostsLayoutContainer className="container self-stretch">
          {data.allContentfulOffer.edges.map((item, i) => (
            <OfferPostItem {...item.node} key={item.node.id} />
          ))}
        </BlogPostsLayoutContainer>
      </Layout>
    </>
  )
}

export default OffersPage
